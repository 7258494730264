@import "fonts";
.menu {
  &--desktop-logo {
    width: 330px;
  }

  &--mobile-logo {
    height: auto;
    padding-top: 10px;

    @include mq($from: mobile) {
    width: 50%;
    padding-top: 15px;
    left: 23%;}

    @include mq($from: tablet) {
    width: 30%;
    padding-top: 8px;
    left: 32%;
    }
  }
  &--desktop-wrapper {
    margin-top: 85px;
  }
}

@media only screen and (max-width: 768px) and (min-width: 520px) {
  .menu--mobile-logo {
    padding-top: 10px !important;
    width: 35% !important;
    left: 35% !important;
  }
}

@media only screen and (max-width: 335px) and (min-width: 0px) {
  #mce-EMAIL {
    min-width: 261px !important;
  }

  .menu--mobile-logo {
    padding-top: 15px;
  }

}



@media only screen and (max-width: 1320px) and (min-width: 1024px) {
  .sticky--header {
    height: 100px;
  }

  .header-right .display-row {
    margin-top: 10px !important;
  }

  .menu--desktop-wrapper li {
    margin-right: 25px;
    font-size: $menu-font-size;
  }

  .menu--desktop-logo {
    margin-top: -110px !important;
  }

  .menu--desktop-logo {
    max-height: none !important;
  }

}
